import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
 

// Create a custom Axios instance with your desired configuration
const instance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',

}
});


const instance2 = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'apikey': process.env.JS_API_KEY,

}
});
const errorLoggerURLTransformer = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'apikey': process.env.URL_TRANSFORMER_ERROR_LOG_KEY,

  }
});

 

// Define a type for your API response data 
interface ApiResponse<T> {
  success: boolean;
  data: T;
  message: string;
}

 

// Define a type for your API error response data 
interface ApiError {
  error: string;
  code: number;
  Message?: number;
}


async function apiRequest<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleApiError(error); // Handle and rethrow the error
  }
}


export async function apiRequest2<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance2.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleApiError(error); // Handle and rethrow the error
  }
}
export async function errorLoggerRequest<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await errorLoggerURLTransformer.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleApiError(error); // Handle and rethrow the error
  }
}

export async function apiRequestForWrapper<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleWrapperApiError(error); // Handle and rethrow the error
  }
}
 
 
 

// Handle API errors and extract meaningful information
function handleApiError(error: AxiosError<ApiError>): Error {
  if (error.response) {
    // The request was made and the server responded with a non-2xx status code
    const errorMessage = error.response.data.error || 'API Error';
    const statusCode = error.response.status;
    return new Error(`${errorMessage} (Status: ${statusCode})`);
  } else if (error.request) {
    // The request was made, but no response was received
    return new Error('No response received from the server');
  } else {
    // Something happened in setting up the request that triggered an error
    return new Error('Error occurred while setting up the request');
  }
}

function handleWrapperApiError(error: AxiosError<ApiError>): Error {
  if (error.response) {
    // The request was made and the server responded with a non-2xx status code
    const errorMessage = error.response.data.Message ?? 'API Error';
    const statusCode = error.response.status;
    const data = {
      message: errorMessage,
      status: statusCode
    }
    return new Error(JSON.stringify(data));
  } else if (error.request) {
    // The request was made, but no response was received
    return new Error('No response received from the server');
  } else {
    // Something happened in setting up the request that triggered an error
    return new Error('Error occurred while setting up the request');
  }
}

 

export default apiRequest;